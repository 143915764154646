import React, { Component } from "react"
import story from "../../../images/story.png"
import story2 from "../../../images/story2.png"
import { COLORS } from "../../../utils/Colors"

export default class OurStory extends Component {
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="d-md-flex">
            <div className="d-inline-block col-12 col-md-4">
              <span className="d-inline-block col-4 col-md-3" />
              <div className="d-inline-block col-8 col-md-9">
                <div
                  className="d-flex justify-content-center"
                  style={{ justifyContent: "center" }}
                >
                  <img
                    className="img-fluid"
                    style={{ height: 100 }}
                    src={story}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="d-md-inline-block col-12 col-md-8 text-md-right"
              // style={{ textAlign: "end" }}
            >
              {/* <span className="d-inline-block col-2" /> */}
              <div className="d-inline-block col-12">
                <div
                  style={{
                    color: COLORS.THEME_COLOR,
                    fontSize: 22,
                    lineHeight: 1,
                    fontFamily: "SFProHvy",
                    // letterSpacing: -0.4,
                  }}
                >
                  OUR
                  <div
                    style={{
                      fontSize: 46,
                      fontFamily: "SFProReg",
                    }}
                  >
                    STORY
                  </div>
                </div>
                <div style={styles.description}>
                  Riktam Technology Consulting was founded in 2007 as a web
                  applications development service provider. We added iOS dev
                  services in early 2009 (shortly after the appstore began), and
                  Android soon after. Today, we are a leading provider of
                  quality engineering solutions to enterprises and startups,
                  having worked on over 500 projects over the years.
                </div>
              </div>
            </div>
          </div>
          <span className="d-inline-block col-sm-1" />
          <div
            className="d-flex justify-content-center d-md-inline-block col-12 col-md-4"
            // style={{ backgroundColor: "blue" }}
          >
            <img
              className="img-fluid"
              // style={{ height: 150 }}
              src={story2}
              alt=""
            />
          </div>
          <div className="d-inline-block col-11 col-sm-12 col-md-7 text-md-right">
            <span className="d-none d-md-inline-block col-md-2" />
            <div
              className="d-md-inline-block col-md-10"
              style={styles.description}
            >
              Brands we have worked for include Pringles, Oral-B, Mead Johnson,
              Friesland Campina, Versace, Nike, Toyota, Tata Capital, Google,
              Digicel, HCL Technologies
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  subContainer: {
    backgroundColor: COLORS.PROJECTS_BACKGROUND,
    paddingTop: 60,
    paddingBottom: 50,
  },

  description: {
    // fontSize: 14,
    color: COLORS.CONTACT_DETAILS_HEADING,
    lineHeight: 1.2,
    // letterSpacing: -0.4,
    paddingTop: 50,
    fontFamily: "SFProSmBold",
  },
}
