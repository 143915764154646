import React, { Component } from "react"
import AboutCmp from "../components/Description/About/About"
import Layout from "../components/Layout"

export default function About() {
    return (
        <Layout>
            <AboutCmp />
        </Layout>
    )
}
