import React, { Component } from "react"
import aboutClients from "../../../images/aboutClients.png"
import { COLORS } from "../../../utils/Colors"

export default class Customers extends Component {
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="col-12 col-md-5" style={styles.image}>
            <div className="d-flex justify-content-end">
              <img
                className="img-fluid"
                style={{ height: 200 }}
                src={aboutClients}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-md-7" style={styles.form}>
            <span className="d-none d-sm-inline-block col-sm-1" />
            <div className="d-inline-block col-12 col-sm-11">
              <div style={styles.description} className="pl-4 d-inline-block col-12">
                Enterprise customers include{" "}
                <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                  Procter & Gamble, Dr Reddy's Labs, Friesland Campina, Globe
                  Telecom, Axiate, Singapore Mass Rapid Transit
                </span>
              </div>
              <span className="col-md-1" />
            </div>
            <span className="d-none d-sm-inline-block col-sm-1" />
            <div className="d-inline-block col-12 col-sm-11">
              <div
                style={styles.description}
                className="pl-4 d-inline-block col-sm-12"
              >
                Other customers include{" "}
                <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                  LoveFilm UK
                </span>{" "}
                (now part of Amazon)
                <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                  , Real Clear Politics, Packetzoom{" "}
                </span>
                (now part of Roblox)
                <span style={{ color: COLORS.HIGHlIGHTED_TEXT }}>
                  , Universal Tennis Ratings
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  subContainer: {
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
    paddingTop: 60,
    paddingBottom: 50,
  },

  description: {
    // fontSize: 14,
    color: COLORS.CONTACT_DETAILS_HEADING,
    lineHeight: 1.2,
    // letterSpacing: -0.4,
    paddingTop: 50,
    fontFamily: "SFProSmBold",
  },
  image: {
    position: "relative",
    float: "right",
  },
  form: {
    position: "relative",
    float: "left",
  },
}
