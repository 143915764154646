import React, { Component } from "react"
import groupPic from "../../../images/groupPic.png"
import { COLORS } from "../../../utils/Colors"

export default class KeyPeople extends Component {
  getPerson(image, name, designation) {
    return (
      <div className="p-0 d-inline-block col-12 col-sm-6 col-md-3">
        <div
          className="col-12"
          style={{
            textAlign: "center",
            paddingTop: 60,
            fontFamily: "SFProSmBold",
          }}
        >
          <div
            className="d-inline-block col-12"
            style={{
              width: 120,
              height: 120,
              borderRadius: 100,
              backgroundColor: COLORS.CONTACT_DETAILS,
            }}
          />
          {/* <span className="d-none d-sm-inline-block col-sm-2" /> */}
          <div
            className="col-12"
            style={{
              paddingTop: 5,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            {name}
          </div>
          <div style={{ fontFamily: "SFProBold" }}>{designation}</div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="col-12">
            {/* <span className="d-inline-block col-sm-1" /> */}
            <div
              style={{
                color: COLORS.THEME_COLOR,
                fontSize: 22,
                lineHeight: 1,
                fontFamily: "SFProHvy",
              }}
              className="pl-0 d-inline-block col-sm-12"
            >
              {/* KEY PEOPLE */}
              {/* Riktam */}
              <div
                className="d-flex justify-content-center"
                style={{
                  fontSize: 46,
                  fontFamily: "SFProReg",
                }}
              >
                {/* WORKING AT TIGERSHEET */}
              Riktam Team
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center col-12">
            {/* <div className="pl-5 pr-5 col-12">
              {this.getPerson("image", "Siddharam Shingshetty", "CEO")}
              {this.getPerson("image", "Anita Sigglenmund", "Support")}
              {this.getPerson("image", "Anita Sigglenmund", "Support")}
              {this.getPerson("image", "Anita Sigglenmund", "Support")}
            </div> */}
            <img
                className="col-12 col-md-8 img-fluid"
                style={{ paddingTop:50 }}
                src={groupPic}
                alt=""
              />
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    paddingBottom: 50,
  },
  subContainer: {
    backgroundColor: COLORS.PROJECTS_BACKGROUND,
    paddingTop: 60,
    paddingBottom: 50,

    //  backgroundColor: "pink"
  },
  image: {
    position: "relative",
    float: "right",
  },
}
