import React, { Component } from "react"
import StartupSpecialist from "./StartupSpecialist"
import OurStory from "./OurStory"
import Customers from "./Customers"
import KeyPeople from "./KeyPeople"

export default class About extends Component {
  render() {
    return (
      <div className="container-fluid">
        <StartupSpecialist />
        <OurStory />
        <Customers />
        <KeyPeople />
      </div>
    )
  }
}
