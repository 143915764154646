import React, { Component } from "react"
import startupSpecialist from "../../../images/startupSpecialist.png"
import { COLORS } from "../../../utils/Colors"

export default class StartupSpecialist extends Component {
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="col-12 col-md-6" style={styles.image}>
            <div className="d-flex justify-content-center">
              <img
                className="img-fluid"
                style={{ height: 250 }}
                src={startupSpecialist}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-md-6" style={styles.form}>
            <span className="d-none d-md-inline-block col-md-2" />
            <div
              style={{
                color: COLORS.THEME_COLOR,
                fontSize: 22,
                fontFamily: "SFProHvy",
                // fontWeight: "bold",
                lineHeight: 1,
                // letterSpacing: -0.4,
                paddingTop: 60,
              }}
              className="pl-1 d-inline-block col-md-9 col-lg-6"
            >
              THE STARTUP
              <div
                style={{
                  fontSize: 46,
                  // letterSpacing: 0.66,
                  // fontWeight: "normal",
                  fontFamily: "SFProReg",
                }}
              >
                SPECIALIST
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6" style={styles.form}>
            <span className="d-none d-md-inline-block col-md-2" />
            <div
              style={styles.description}
              className="pl-1 d-inline-block col-md-9"
            >
              Riktam is a technology services company with a core strength in
              hiring, training and motivating quality software engineers. We
              only hire 1 in 500 applications, run our hires through an intense
              bootcamp and bring them up on a steady diet of challenging work.
            </div>
            <span className="d-none d-md-inline-block col-md-2" />
            <div
              style={styles.description}
              className="pl-1 d-inline-block col-md-10"
            >
              We offer engineering services primarily to fast growing startup
              teams looking to augment their dev team with quality tech talent.
              We only work with 3-4 clients at a time and pride ourselves on
              being adaptive to your needs and aligned to your success. Speak to
              our past customers!
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  subContainer: {
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
    paddingBottom: 50,

    //  backgroundColor: "pink"
  },
  image: {
    position: "relative",
    float: "right",
    paddingTop: 60,
  },
  form: {
    position: "relative",
    float: "left",
  },
  description: {
    // fontSize: 14,
    fontFamily: "SFProSmBold",
    color: COLORS.CONTACT_DETAILS_HEADING,
    lineHeight: 1.2,
    // letterSpacing: -0.4,
    paddingTop: 30,
  },
}
